/* eslint-disable no-magic-numbers */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PureComponent } from 'react';
import { Provider } from 'react-redux';
import { Provider as UnstatedProvider } from 'unstated';

import Router from 'Component/Router';
import SharedTransition from 'Component/SharedTransition';
import SomethingWentWrong from 'Route/SomethingWentWrong';
import { getStaticReducers } from 'Store/index';
import BrowserDatabase from 'Util/BrowserDatabase';
import { noopFn } from 'Util/Common';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/Config';
import getStore, { injectReducers } from 'Util/Store';

import { AB_TEST_VERSION } from './App.config';

/** @namespace Satisfly/Component/App/Component */
export class AppComponent extends PureComponent {
    reduxStore = undefined;

    productionFunctions = [
        this.disableReactDevTools.bind(this),
        this.injectComment.bind(this),
    ];

    developmentFunctions = [
        this.enableHotReload.bind(this),
    ];

    commonFunctions = [
        this.configureStore.bind(this),
    ];

    rootComponents = [
        this.renderRouter.bind(this),
        this.renderSharedTransition.bind(this),
    ];

    contextProviders = [
        this.renderRedux.bind(this),
        this.renderUnStated.bind(this),
    ];

    state = {
        isSomethingWentWrong: false,
        errorDetails: undefined,
    };

    __construct(props) {
        super.__construct?.(props);

        this.configureAppBasedOnEnvironment();
        this.configureApp();
        // @ts-ignore:next-line
        const { ab_test } = window.contentConfiguration || {};
        const isABTestEnabled = ab_test?.enabled === '1';
        const currentVersion = BrowserDatabase.getItem(AB_TEST_VERSION);

        if (isABTestEnabled && !currentVersion) {
            const rand = Math.floor(Math.random() * 100) + 1;
            const version = rand <= ab_test?.a_percent ? 'a' : 'b';

            BrowserDatabase.setItem(
                version,
                AB_TEST_VERSION,
                ONE_MONTH_IN_SECONDS,
            );
        }

        if (!isABTestEnabled) {
            BrowserDatabase.deleteItem(AB_TEST_VERSION);
        }
    }

    componentDidCatch(err, info) {
        this.setState({
            isSomethingWentWrong: true,
            errorDetails: { err, info },
        });
    }

    renderSharedTransition() {
        return (
            <SharedTransition key="transition" />
        );
    }

    configureStore() {
        const store = getStore();

        injectReducers(store, getStaticReducers());

        this.reduxStore = store;
    }

    renderRedux(children) {
        if (!this.reduxStore) {
            return null;
        }

        return (
            <Provider store={ this.reduxStore } key="redux">
                { children }
            </Provider>
        );
    }

    renderUnStated(children) {
        return (
            <UnstatedProvider key="unstated">
                { children }
            </UnstatedProvider>
        );
    }

    enableHotReload() {
        if (module.hot) {
            module.hot.accept();
        }
    }

    injectComment() {
        const comment = document.createComment('Powered by ScandiPWA (scandipwa.com)');

        document.querySelector('html')?.appendChild(comment);
    }

    /**
     * Disable react-dev-tools
     * @link https://github.com/facebook/react-devtools/issues/191#issuecomment-367905536
     */
    disableReactDevTools() {
        if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object') {
            // eslint-disable-next-line no-restricted-syntax, fp/no-loops, no-unused-vars
            for (const [key, value] of Object.entries(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
                window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = typeof value === 'function' ? noopFn : null;
            }
        }
    }

    configureAppBasedOnEnvironment() {
        const functionsToRun = process.env.NODE_ENV === 'production'
            ? this.productionFunctions
            : this.developmentFunctions;

        functionsToRun.forEach((func) => func());
    }

    configureApp() {
        this.commonFunctions.forEach((func) => func());
    }

    handleErrorReset() {
        this.setState({ isSomethingWentWrong: false });
    }

    renderRouter() {
        return (
            <Router key="router" />
        );
    }

    renderRootComponents() {
        return this.rootComponents.map((render) => render());
    }

    renderContextProviders() {
        const { isSomethingWentWrong } = this.state;

        const child = isSomethingWentWrong
            ? this.renderSomethingWentWrong.bind(this)
            : this.renderRootComponents.bind(this);

        return this.contextProviders.reduce(
            (acc, render) => render(acc),
            [child()],
        );
    }

    renderSomethingWentWrong() {
        const { errorDetails = {} } = this.state;

        return (
            <SomethingWentWrong
              onClick={ this.handleErrorReset }
              errorDetails={ errorDetails }
              key="something-went-wrong"
            />
        );
    }

    render() {
        return this.renderContextProviders();
    }
}

export default AppComponent;
