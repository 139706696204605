/* eslint-disable no-magic-numbers */
/**
 * Stripe compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

// eslint-disable-next-line react/no-deprecated
import { render } from 'react-dom';

import App from 'Component/App';

import 'Style/main';

if ('serviceWorker' in navigator) {
    const swUrl = '/service-worker.js';
    navigator.serviceWorker.register(swUrl, { scope: '/' });
}

if (window.metaHtml) {
    const doc = new DOMParser().parseFromString(window.metaHtml, 'text/html');
    Object.values(doc?.head?.children || {}).forEach((node) => document.head.appendChild(node));
}

// Code bellow enables the hot reloading of plugins
// Why? I have no idea. Further debugging needed.
// TODO: understand why this helps HMR
if (module.hot) {
    module.hot.accept();
}

/** @namespace Satisfly/Render/HotApp */
export function HotApp() {
    return <App />;
}

export const container = document.getElementById('app-root');

if (!container) {
    setTimeout(() => {
        const c = document.getElementById('app-root');

        if (!c) {
            setTimeout(() => {
                const d = document.getElementById('app-root');

                if (!d) {
                    const a = document.createElement('div');
                    a.setAttribute('id', 'app-root');
                    document.body.appendChild(a);
                    const b = document.getElementById('app-root');

                    render(<HotApp />, b);
                }
            }, 1000);
        } else {
            render(<HotApp />, c);
        }
    }, 200);
} else {
    render(<HotApp />, container);
}
