/* eslint-disable @scandipwa/scandipwa-guidelines/file-structure */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { UPDATE_ITEM_RETURN_FORM } from './ItemReturnForm.action';

export const initialState = {
    isLoading: false,
};

/** @namespace Satisfly/Store/ItemReturnForm/Reducer/ItemReturnFormReducer */
export const ItemReturnFormReducer = (state = initialState, action) => {
    const {
        type,
        data,
    } = action;

    if (type === UPDATE_ITEM_RETURN_FORM) {
        return { ...state, ...data };
    }

    return state;
};

export default ItemReturnFormReducer;
