/* eslint-disable @scandipwa/scandipwa-guidelines/file-structure */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export const SET_LABELS = 'SET_LABELS';

/** @namespace Satisfly/Store/Label/Action/setLabels */
export const setLabels = (labels) => ({
    type: SET_LABELS,
    labels,
});
