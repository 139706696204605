/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export const UPDATE_SHIPPING_FIELDS = 'UPDATE_SHIPPING_FIELDS';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_EMAIL_AVAILABLE = 'UPDATE_EMAIL_AVAILABLE';
export const SET_IS_COMPANY = 'SET_IS_COMPANY';
export const SET_PAYMENT_FORM_VALID = 'SET_PAYMENT_FORM_VALID';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';
export const SET_INITIAL_SHIPPING_METHOD = 'SET_INITIAL_SHIPPING_METHOD';

/** @namespace Satisfly/Store/Checkout/Reducer/getInitialState */
export const getInitialState = () => ({
    shippingFields: {},
    email: '',
    isEmailAvailable: true,
    isCompany: false,
    isPaymentFormValid: false,
    paymentMethod: '',
    initialShippingMethod: {},
});

/** @namespace Satisfly/Store/Checkout/Reducer/CheckoutReducer */
export const CheckoutReducer = (
    state = getInitialState(),
    action,
) => {
    switch (action.type) {
    case SET_INITIAL_SHIPPING_METHOD:
        const { initialShippingMethod } = action;

        return {
            ...state,
            initialShippingMethod,
        };

    case UPDATE_SHIPPING_FIELDS:
        const { shippingFields } = action;

        return {
            ...state,
            shippingFields,
        };

    case UPDATE_EMAIL:
        const { email } = action;

        return {
            ...state,
            email,
        };

    case UPDATE_EMAIL_AVAILABLE:
        const { isEmailAvailable } = action;

        return {
            ...state,
            isEmailAvailable,
        };

    case SET_IS_COMPANY:
        const { isCompany } = action;

        return {
            ...state,
            isCompany,
        };

    case SET_PAYMENT_FORM_VALID:
        const { isPaymentFormValid } = action;

        return {
            ...state,
            isPaymentFormValid,
        };

    case SET_PAYMENT_METHOD:
        const { paymentMethod } = action;

        return {
            ...state,
            paymentMethod,
        };

    default:
        return state;
    }
};

export default CheckoutReducer;
