/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    SAVE_INSPIRATION,
    SAVE_INSPIRATIONS,
    SAVE_INSPIRATIONS_FILTERS,
    SET_INSPIRATION_LOADING,
} from './Inspirations.action';

export const initialState = {
    isLoading: true,
    inspirations: [],
    inspiration: {},
    filters: [],
    total_count: 0,
    total_pages: 1,
};

/** @namespace Satisfly/Store/Inspirations/Reducer/InspirationsReducer */
export const InspirationsReducer = (state = initialState, action) => {
    const {
        type,
        data,
    } = action;

    switch (type) {
    case SAVE_INSPIRATIONS:
        return {
            ...state,
            inspirations: data.items,
            total_count: data.total_count,
            total_pages: data.total_pages,
        };

    case SAVE_INSPIRATION:
        return { ...state, inspiration: data };

    case SAVE_INSPIRATIONS_FILTERS:
        return { ...state, filters: data };

    case SET_INSPIRATION_LOADING:
        return { ...state, isLoading: data };
    default:
        return state;
    }
};

export default InspirationsReducer;
