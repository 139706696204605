/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { lazy } from 'react';

import { UrlRewritesComponent as SourceUrlRewritesComponent } from 'SourceRoute/UrlRewrites/UrlRewrites.component';

import {
    UrlRewritePageType,
} from './UrlRewrites.config';

export const ProductPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "product" */ 'Route/ProductPage'));
export const CategoryPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "category" */ 'Route/CategoryPage'));
export const CmsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/CmsPage'));
export const InspirationsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "inspirations" */ 'Route/InspirationsPage'));
export const NoMatch = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "misc" */ 'Route/NoMatch'));

/**
 * Additional types possible:
 * const TYPE_PWA = 'PWA_ROUTER';
 * const TYPE_CUSTOM = 'CUSTOM';
 * @namespace Satisfly/Route/UrlRewrites/Component */
export class UrlRewritesComponent extends SourceUrlRewritesComponent {
    renderInspirationsPage() {
        const { props } = this.props;
        const {
            location,
            match,
            inspirationId,
        } = props;

        return (
            <InspirationsPage
              location={ location }
              match={ match }
              inspirationId={ inspirationId }
            />
        );
    }

    renderContent() {
        const { type } = this.props;

        switch (type) {
        case UrlRewritePageType.PRODUCT:
            return this.renderProductPage();
        case UrlRewritePageType.CMS_PAGE:
            return this.renderCmsPage();
        case UrlRewritePageType.CATEGORY:
            return this.renderCategoryPage();
        case UrlRewritePageType.SF_INSPIRATIONS_INDEX:
        case UrlRewritePageType.SF_INSPIRATION:
            return this.renderInspirationsPage();
        case UrlRewritePageType.NOTFOUND:
            return this.renderNoMatch();
        default:
            return this.renderDefaultPage();
        }
    }
}

export default UrlRewritesComponent;
